import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";
import leisha_img_1 from '../../../images/Leisha-McKinley-Beach-hero.jpg';
import leisha_img_1_mobile from '../../../images/Leisha-McKinley-Beach-hero-mobile.jpg';

const fullName = "Leisha McKinley Beach";

const copyList = [

    {
        type: "image",
        content: {
            src: leisha_img_1,
            mobileSrc: leisha_img_1_mobile,
            alt: "Text reads The Nurturer over a woman looking confidently into camera",
            caption: "Text reads The Nurturer over a woman looking confidently into camera"
        }
    },
    {
        type: "text",
        content: "<span class=\"h3\">A conversation with Leisha McKinley Beach, a National HIV consultant and CEO of Black Public Health Academy</span>"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Nuturer"
    // },

    {
        type: 'text',
        content: "Not many people can pinpoint the very moment their lives changed forever, but Leisha McKinley Beach can. Long before she became the renowned HIV/AIDS expert, advocate, and consultant she is today, she was a 19-year-old student at the University of Florida in a situation she never thought she’d find herself. “I was the only woman in a group called the AIDS peer educators,” she says of the first student organization she saw and joined, figuring it would look good on her resume. “It was made up of White and Black gay men.” One of the members had HIV and became severely ill. When he died, she was there holding his hand, standing in place of his mother, who hadn’t come. “He was only 21,” she says. One day, not long after, she was walking across campus, and “I heard this voice that said, ‘Your goal in life is to love.’”"
    },

    {
        type: 'text',
        content: "To this day, Beach, a woman of faith, is convinced that it was the voice of God. That voice has inspired her to lead with love in the HIV/AIDS space ever since that day. Says Beach, “My work all these years has been centered around that. Just love. Everything else will fall into place.”"
    },

    {
        type: "blockquote",
        content: "“My work all these years has been centered around that. Just love. Everything else will fall into place.”",
        author: fullName
    },
    {
        type: "text",
        content: `In her 30-plus years in the field, “I think my tenure in the Florida and Atlanta health 
        departments has brought me the most pleasure and pain, trying to serve with a system that was never 
        designed for great health outcomes for Black people.” Fifteen years ago, she helped launch Sistas 
        Organizing to Survive, which sprung out of dire need. “In 2007, Black women far and away made up the highest percentages of 
        Florida HIV cases compared to women in other racial groups.”`
    },
    {
        type: "text",
        content: `Through the initiative, which pulled together Black women from all walks of life for widespread 
        HIV testing and prevention services, Beach dedicated herself to helping change that data. “When women 
        bought into the vision, we exceeded our desired outcome,” she says. “It’s now the longest-running HIV 
        initiative in the state of Florida, for Black women and by Black women.”`
    },
    {
        type: "text",
        content: "As Beach gears up for retirement, she’s taking stock of what she’s accomplished. “I grew up in HIV,” she says. “My husband would say, ‘She loves me, but that’s her first love.’” From the second she lost her college friend to HIV, there was no way to do this work at arm’s length. “You can’t be at the bedsides of people dying, people living in fear, and not be connected,” says Beach. If she could, she’d tell HIV, “You’ve taken so much from me, but if I’m honest, I’ve also gained family, friends, resilience, and love from fighting you.” Even still, she adds, “I look forward to your end very soon.” When that end comes, she’ll be one of the leaders receiving all the love she’s due. History, she says, “will record my commitment to help stop the spread of HIV. That is my legacy.”"
    },
    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">LEISHA McKINLEY BEACH"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">National HIV Consultant and CEO of Black Public Health Academy"
    // },
    {
        type: "text",
          content: "<a class=\"button large secondary\" href=\"/championsofchange\">Learn more about all our Champions of Change leaders"

    },

]


class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'}`}>

      <ArticleLayout
        page={this.props.page}
        ariaLabel="Group of smiling Black women in baroque clothing sitting together in front of a purple velvet curtain"
        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Leisha McKinley Beach, The Nurturer"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}
      

      </div>
    );
  }
}

export default Content;
